import React, { useEffect, useContext, useState, useRef } from 'react';
import './styles.scss';
import logoSquad from '../../../../../src/assets/images/logo_expired.png';

export default function ContractExpired({ onRender, onClose }) {

  const modalRef = useRef(null);
  
    useEffect(() => {
      const observer = new MutationObserver(() => {
        if (!modalRef.current || !document.body.contains(modalRef.current)) {
          console.warn("Tentativa de remoção detectada! Recriando...");
          document.body.appendChild(modalRef.current);
        }
      });
  
      observer.observe(document.body, { childList: true, subtree: true });
  
      return () => observer.disconnect();
    }, []);

  return (
      <div className="aimodal-modal" ref={modalRef}>
      <div className="aimodal-modal-content">
        <div className='header'>
          <div className='close'>
            {/* <button className="close-button" onClick={closeButton} aria-label="Fechar">
              &times;
            </button> */}
          </div>
          <span className='title-header'><b>Periodo de Avaliação Expirado</b></span>
        </div>
        <div className='image-div-contract'>
          <img src={logoSquad} alt="Tour" className="aimodal-image" />
        </div>
        <div className='text'>
          <div className='sub-title'> 
            <span className='welcome'> <b>Entre em contato com os administradores da sua organização</b> </span>
          </div>
          <p>
          Agradecemos por utilizar nossa ferramenta durante o período de avaliação. Não hesite em entrar em contato com os administradores da sua organização para discutir as próximas etapas e como continuar aproveitando nossos recursos.
          </p>
        </div>
        <div className='button-container'>
          {/* <button className="start-aimodal" onClick={submitButton}>
            OK
          </button> */}
        </div>
      </div>
    </div>
        )
}
