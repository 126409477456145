import React, { useRef, useEffect } from "react";
import ClockImg from "../../../../../src/assets/images/clock_time.png";
import "./styles.scss";

export default function AccessExpiredModal() {
  const modalRef = useRef(null);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (!modalRef.current || !document.body.contains(modalRef.current)) {
        console.warn("Tentativa de remoção detectada! Recriando...");
        document.body.appendChild(modalRef.current);
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  return (
    <div ref={modalRef} className="expired-modal">
      <div className="expired-modal-content">
        <div className="expired-header">
          <span className="expired-title">Tempo de Acesso Expirado</span>
        </div>
        <div className="expired-image-container">
          <img src={ClockImg} alt="Tempo Expirado" className="expired-image" />
        </div>
        <div className="expired-text">
          <span className="expired-subtitle">
            Sua organização definiu um horário limitado de acesso.
          </span>
          <p>
            Caso identifique algum erro na sua carga horária, entre em contato com o setor responsável para mais informações.
          </p>
        </div>
      </div>
    </div>
  );
}
